import React, {useState, useEffect} from 'react';
import "./RegisterComponent.scss";
import Logo from '../assets/logo.png';
import {Alert} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import {fetchData} from '../services';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

function ConfirmAccountComponent(props){
  const params = useParams();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(()=>handleFetchItem(),[params?.id]);

  const handleFetchItem = () => {
    const {id} = params;
    const {hasChecked,setHasChecked} = props;
    if(id && !hasChecked){
      setHasChecked();
      fetchItem(id);
    };
  }

  const fetchItem = (id) => {
    const {setIsTokenWrong,setErrorMessage} = props;
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/confirm/${id}`)
      .then(data => {
        if(!data.success){
          setIsTokenWrong(true);
          setErrorMessage(data.message);
        }
      })
      .finally(() => {
        setIsFetching(false);
      })
      .catch(err=>console.warn(err));
  }

  const renderContent = () => {
    const {hasChecked,isTokenWrong,errorMessage} = props;
    return (
      <div>
        <img src={Logo} className="logo" alt="Logo"/>
        {!isFetching && (
          !hasChecked ? (
            <Alert variant="primary">Poczekaj na potwierdzenie konta</Alert>
          ) : (
            isTokenWrong ? (
              <Alert variant="warning">{errorMessage}</Alert>
            ) : (
              <Alert variant="success">Twoje konto zostało potwierdzoene, możesz się <Link to="/find-out/login">zalogować</Link></Alert>
            ))
          )}
      </div>
    )
  }

  return (
    <div className="account-confirmation">
      {renderContent()}
    </div>
  )
}

export default ConfirmAccountComponent;
