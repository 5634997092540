import React from 'react';
import {fetchData} from '../../services';
import EmptyRelationForm from './Forms/EmptyRelationForm';
import RelationDetailsComponent from './RelationDetailsComponent';
import LoaderComponent from '../../Loader/LoaderComponent';
import {configKeys} from './relation.data';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

export default class FreeCheckComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isLoading: false,
      showResults: false,
    }
    this.createFreeCheck = this.createFreeCheck.bind(this);
  }

  componentDidMount(){
    if(this.props.consumedSingleCheck){
      this.setState({
        showResults: true,
      })
    }
  }

  createFreeCheck(ev,state){
    ev.preventDefault();
    let data = {};
    configKeys.forEach(k => data[k] = state[k]);
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/user/api/relation/single-check`,'POST',data)
      .then(data=>{
        if(data){
          if(data.errorMessage) window.alert(data.errorMessage);
          if(data.success) window.location.replace('/find-out/app')
        }
      })
      .finally(()=>{
        this.setState({isLoading: false});
      })
  }

  renderForm(){
    return (
      <EmptyRelationForm
        onSubmit={this.createFreeCheck}
      />
    )
  }

  renderResults(){
    return (
      <RelationDetailsComponent isFreeCheck={true} />
    )
  }

  render(){
    const {isLoading,showResults} = this.state;
    return isLoading ? <LoaderComponent /> : showResults ? this.renderResults() : this.renderForm();
  }
}
