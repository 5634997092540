import React from 'react';
import './Dashboard.scss';
import { Outlet, Navigate } from "react-router-dom";
import MenuComponent from '../Menu/MenuComponent';
import Logo from '../assets/logo_blank.png';

export default class DashboardComponent extends React.Component {

  render(){
    const {isLoggedIn,isAdmin} = this.props;
    return (
      <div className="dashboard">
        {!isAdmin && (<Navigate to="/" redirect={true} />)}
        {!isLoggedIn && (<Navigate to="/find-out/login" redirect={true} />)}
        <header>
          <img src={Logo} className="logo" alt="Logo"/>
          <nav>
            <MenuComponent/>
          </nav>
        </header>
        <section>
          <Outlet/>
        </section>
      </div>
    );
  }
}
